import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material';
import React, { FC, useState } from 'react';
import { colors } from '../../../theme/colors';
import ProgressSpinner from '../../../components/ProgressSpinner';
import { useRouter } from 'next/router';
import { Overline, Paragraph } from '../../../theme/text-variants';
import StoresList from './StoresList';
import ChevronRight from '@/img/icons/chevron-right.svg';
import Avatar from '../../Avatar';
import { User } from '../../../api';
import CreateLiveShowButton from '../../buttons/CreateLiveShowButton';
import List from '@mui/material/List';
import ListItem from '../../lists/ListItem';
import Settings from '@/img/settings.svg';
import StoreFrontIcon from '@/img/storefront.svg';
import { useDispatch } from 'react-redux';
import { storePreviousRouteDetails } from 'store/reducers/app.reducer';

interface StoreViewProps {
  shops: any;
  user?: User;
}

const StoreView: FC<StoreViewProps> = ({ shops, user }) => {
  const [createShowBtnClicked, setCreateShowBtnClicked] = useState(false);
  const { push, pathname, query } = useRouter();
  const dispatch = useDispatch();

  // Define the ListItem data as an array
  const listItems = [
    {
      size: 'large',
      startIcon: <Settings />,
      option: 'Account Settings',
      onClick: () => {
        dispatch(
          storePreviousRouteDetails({
            previousRoute: window.location.pathname
          })
        );
        push({
          pathname: `/settings`
        });
      }
    },
    {
      size: 'large',
      startIcon: <StoreFrontIcon />,
      option: 'Store Settings',
      onClick: () => {
        dispatch(
          storePreviousRouteDetails({
            previousRoute: window.location.pathname
          })
        );
        push({
          pathname: `/${query.shopSlug}/settings`,
        });
      }
    }
  ];

  return (
    <Stack>
      <Stack padding="0 16px 16px 16px">
        <CreateLiveShowButton
          onClick={() => {
            setCreateShowBtnClicked(true);
            push(`/${query?.shopSlug}/shows/create`);
          }}
        >
          {createShowBtnClicked ? <ProgressSpinner size="24px" color={colors.white} /> : 'Create Live Show'}
        </CreateLiveShowButton>
      </Stack>
      <Divider />
      <List>
        {listItems.map((item, index) => (
          <ListItem
            key={index}
            size={item.size as 'small' | 'medium' | 'large'}
            startIcon={item.startIcon}
            option={item.option}
            variant="link"
            onClick={item.onClick}
          />
        ))}
      </List>
      <Divider />
      <Stack padding="16px">
        <Stack pb="8px">
          <Stack height="32px" justifyContent="center">
            <Overline size="xs">ACCOUNT</Overline>
          </Stack>
          <AccountCardContainer
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            onClick={() => push('/')}
          >
            <Stack direction="row" alignItems="center" gap="16px">
              <Avatar alt={``} src={user?.photoUrl ?? ''} />
              <Stack>
                <Paragraph size="md" noWrap truncate>
                  {user?.name}
                </Paragraph>
                <Paragraph size="sm" color={colors.gray700}>
                  {user?.email}
                </Paragraph>
              </Stack>
            </Stack>
            <ChevronRight />
          </AccountCardContainer>
        </Stack>

        {shops?.length > 0 && (
          <>
            <Stack height="32px" justifyContent="center">
              <Overline size="xs">OTHER STORES</Overline>
            </Stack>
            <StoresList shops={shops} />
          </>
        )}
       
      </Stack>
    </Stack>
  );
};

export default StoreView;

const AccountCardContainer = styled(Stack)(() => ({
  ['& :hover']: {
    cursor: 'pointer'
  }
}));
