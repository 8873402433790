import React, { Dispatch, FC, ReactNode, SetStateAction, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import { useGetUser, User, useViewerShops } from '@/api';
import ProgressSpinner from '../../../components/ProgressSpinner';
import OutlineButton from '../../buttons/OutlineButton';
import { useAuth } from '../../../lib';
import SellerView from './SellerView';
import BuyerView from './BuyerView';
import { SwipeableDrawer } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from '../../../theme/colors';
import TextButton from '../../buttons/TextButton';
import { colorTokens } from '../../../theme/color-tokens';
import AddRoundedIcon from '@/img/icons/plus.svg';
import { useRouter } from 'next/router';

interface SideMenuProps {
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  user?: User;
}

const SideMenu: FC<SideMenuProps> = ({ open, setOpen }) => {
  const { shops, loading, viewer } = useViewerShops();
  const { query, push } = useRouter();
  const {
    data: { user }
  } = useGetUser(viewer?.id as string);

  const { logout } = useAuth();

  const [logoutBtnClicked, setLogoutBtnClicked] = useState(false);

  let content: ReactNode;

  if (loading) content = <ProgressSpinner size="16px" />;

  if (shops?.length > 0) {
    content = <SellerView shops={shops} user={user as User} />;
  } else {
    content = <BuyerView />;
  }

  return (
    <Slide direction="right" in={open} onChange={() => setOpen?.(false)}>
      <Backdrop onClick={() => setOpen?.(false)} open={Boolean(open)} sx={{ zIndex: 5000 }}>
        <SwipeableDrawer
          style={{ zIndex: 6000 }}
          onClose={() => setOpen?.(false)}
          onOpen={() => {}}
          open={open}
          anchor="left"
        >
          <Box width="326px" onClick={(e) => e.stopPropagation()} position="relative">
            <Box pt="56px">{content}</Box>
            <LogoutBtnContainer pt="16px" pb="16px" position="fixed" left="16px" bottom="0" width="294px">
              {query?.shopSlug && (
                <Box display={'flex'} justifyContent={'center'} mb={'16px'}>
                  <TextButton
                    textColor={colorTokens.textPrimary}
                    startIcon={<AddRoundedIcon />}
                    onClick={() => push('/shopdetails')}
                  >
                    New Store
                  </TextButton>
                </Box>
              )}

              <OutlineButton
                loading={logoutBtnClicked}
                fullWidth
                size="large"
                onClick={() => {
                  setLogoutBtnClicked(false);
                  logout();
                }}
              >
                Log Out
              </OutlineButton>
            </LogoutBtnContainer>
          </Box>
        </SwipeableDrawer>
      </Backdrop>
    </Slide>
  );
};

export default SideMenu;

const LogoutBtnContainer = styled(Box)(() => ({
  backgroundColor: colors.white
}));
