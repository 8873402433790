import React, { FC, useCallback, useEffect, useState } from 'react';
import { Paper, PaperProps, styled, Stack } from '@mui/material';
import { Heading } from '../../theme/text-variants';
import { useUser } from '@auth0/nextjs-auth0';
import { useGetUsersByEmail, useViewer } from '../../api';
import LoadingOverlay from '../loading/LoadingOverlay';
import { useRouter } from 'next/router';
import { useShopBySlug } from '../../api';
import Logo from '../../img/logo.svg';

import MenuIcon from '@/img/menu.svg';
import { colorTokens } from '../../theme/color-tokens';
import SideMenu from './sideMenu/SideMenu';
import { useRouteChange } from '../../lib/hooks/routing';

interface TopBarProps extends Omit<PaperProps, 'elevation'> {
  shadow?: boolean;
  title?: string;
}

export const TopBar: FC<TopBarProps> = ({ shadow, title, ...props }) => {
  const { push, query } = useRouter();
  const { user, isLoading } = useUser();
  const { data } = useGetUsersByEmail(user?.email as string);
  const { viewer, loading, refetch } = useViewer();
  const { shop } = useShopBySlug(query?.shopSlug as string);
  const [shopPhotoUrl, setShopPhotoUrl] = useState<string>('');
  const { routingStarted, longWaitTime } = useRouteChange();
  const profilePhotoUrl = data?.usersByEmail?.nodes?.[0].photoUrl ?? (user?.picture as string);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const newShopPhotoUrl = shop?.shopCustomizations?.[0]?.shopProfileImage?.originalSrc;
    if (newShopPhotoUrl && !shopPhotoUrl) {
      setShopPhotoUrl(newShopPhotoUrl);
    }
  }, [shop, shopPhotoUrl]);

  const stablePush = useCallback((url) => push(url), [push]);
  useEffect(() => {
    if (!viewer || loading || query.updated) return;
  
    if (!viewer.username || !viewer.email) {
      refetch().then(({ data }) => {
        if (!data?.viewer?.username || !data?.viewer?.email) {
          stablePush({
            pathname: '/signup/create-profile',
            query: { missingInfo: true },
          });
        }
      });
    }
  }, [loading, viewer, query.updated, refetch, stablePush]);

  let profileContent = query?.shopSlug ? (
    <ProfileImage
      imageUrl={shopPhotoUrl}
      onClick={() => {
        push(`/${query.shopSlug}/store-profile`);
      }}
    />
  ) : (
    <ProfileImage
      imageUrl={profilePhotoUrl as string}
      onClick={() => {
        push('/user-profile');
      }}
    />
  );

  useEffect(() => {
    if (routingStarted) {
      setMenuOpen(false);
    }
  }, [routingStarted]);

  return (
    <StyledTopBar shadow={shadow} {...props}>
      <SideMenu open={menuOpen} setOpen={setMenuOpen} />
      <LoadingOverlay open={isLoading} loadingText="Loading..." />
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap="16px" alignItems="center">
          <MenuIcon height="24px" width="24px" onClick={() => setMenuOpen((prev) => !prev)} />
          {title === 'Home' ? (
            <LogoImage onClick={() => push('/')}>
              <Logo />
            </LogoImage>
          ) : (
            <Heading size="md" color={colorTokens.textPrimary}>
              {title}
            </Heading>
          )}
        </Stack>
        <Stack direction="row" gap="8px" alignItems="center">
          {/* <NotificationsIcon height="24px" width="24px" /> */}
          {profileContent}
        </Stack>
      </Stack>
    </StyledTopBar>
  );
};

export default TopBar;

const StyledTopBar = styled(Paper)<TopBarProps>(() => ({
  padding: `8px 16px`,
  boxShadow: 'none'
}));

const ProfileImage = styled(Stack)<{ imageUrl?: string }>(({ imageUrl }) => ({
  width: '32px',
  height: '32px',
  backgroundImage: `url("${imageUrl}")`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundColor: colorTokens.iconSubtle,
  borderRadius: '32px',

  ['& :hover']: {
    cursor: 'pointer !important'
  }
}));

const LogoImage = styled(Logo)`
  width: 143px;
  height: 32px;
  @media (max-width: 820px) {
    width: 93px;
    height: 24px;
    padding-top: 8px;
    margin-right: 22px;
  }
`;
